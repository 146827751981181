#root,
html,
body {
  min-height: auto;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #f8f8fa;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin: 5px 0;
}

h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  margin: 5px 0;
}

ul,
li {
  list-style: none;
  padding-left: 0;
}

/*a {
  text-decoration: none;
  color: #000;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

a:hover {
  opacity: 0.35;
  text-decoration: none;
}

a:active {
  text-decoration: none;
}*/

pre {
  white-space: pre-wrap;
}

.ant-spin-container {
  height: 100%;
}

.ant-upload-drag {
  height: auto !important;
}

label {
  font-size: 14px;
  font-weight: 400;
}
