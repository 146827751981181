.header-title {
  font-weight: bold;
  font-size: 24px;
  color: #fff; /* Text color */
}

.header-title-button {
  height: auto;
}

.user-info {
  color: #fff; /* Text color */
  font-size: 16px;
}

.logout-button {
  margin-left: 8px; /* Margin between user info and logout button */
}
