.card-summary {
	.ant-card-head {
		padding: 0 18px;
	}
	.ant-card-head-title {
		white-space: initial;
	}
	.ant-card-body {
		padding: 18px;
	}
}

.white-box {
	background: white;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
		0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
	border-radius: 16px;
}

.border-box {
	border: 2px solid #f0f0f0;
	border-radius: 16px;
}

.card-pdf-summary {
	.ant-card-head {
		padding: 0 18px;
	}
	.ant-card-head-title {
		white-space: initial;
	}
	.ant-card-body {
		padding: 0px;
		text-align: center;
	}
}