.login-container {
  width: 300px;
  margin: auto;
  margin-top: 100px;
}

.login-link-button {
  padding: 0;
}

.login-form-button {
  width: 100%;
}

.login-form-remember {
  .ant-row .ant-form-item-row {
    width: 100%;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
